// AboutUs.js
import React from 'react';
import './AboutUs.css'; // Add your styles here
import about1 from './img/about1.webp';
import whyus from './img/whyus.webp';
import goal from './img/goal.webp';
import process from './img/Background.webp';
import Footer from './Footer';

function AboutUs() {
  return (
    <main className="main-content glass-effect">
    <section className="about-us">
    <div className="about-content ">
        <section className="aboutcontent container" data-title="About Us">
        <div className='about-left'>
            <h2>About Us</h2>
            <h3>Empowering innovation, one line of code at a time.</h3>
            <p>BlueBug Software crafts software, websites, and marketing solutions to enhance your business and boost success effortlessly.</p><br/>
          </div>
          <div className='about-right'>
            <img src={about1} alt="Service 1"/>
          </div>
        </section>
        <section className="aboutcontent rev-about container" data-title="Our Goal">
        <div className='about-left'>
            <img src={goal} alt="Service 1"/>
          </div>
        <div className='about-right'>
            <h3>Our Goal</h3>
            {/* <h5>Empowering innovation, one line of code at a time.</h5> */}
            <p>Our goal is straightforward yet profound: to provide state-of-the-art software solutions that address practical issues. We aspire to lead this revolution because we think technology has the ability to drastically alter the world. We aim to be a top software company in nepal and globally.
            </p><br/>
          </div>
          
        </section>
        <section className="aboutcontent container" data-title="Why Choose Us">
        <div className='about-left'>
            <h3>Why Choose Us?</h3>
            
            <p>Selecting BlueBug Software means collaborating with a business that genuinely wants you to succeed. We take the time to learn about your aims, problems, and vision in order to guarantee that the solutions we offer are not only practical but also in line with your long-term ambitions. We suggest customized software development as per your needand requirements. We can rapidly and effectively produce outcomes by adjusting to your needs thanks to our agile development process.</p><br/>
          </div>
          <div className='about-right'>
            <img src={whyus} alt="Service 1"/>
          </div>
        </section>
        <section className="aboutcontent container" data-title="Our Working Process">
        <div className='about-left wip'>
        <h3>Our Working Process</h3>
            <img src={process} alt="Service 1"/>
          </div>
          
          <div className='about-right'>

          <ul>
            <li><strong>Discovery:</strong>  We begin by understanding your needs and objectives to create a tailored solution.</li>
            <li><strong>Design:</strong> Our team crafts detailed designs and prototypes to ensure alignment with your vision.</li>
            <li><strong>Development:</strong> We build the solution with the latest technologies and best practices.</li>
            <li><strong>Testing:</strong> Rigorous testing is performed to ensure quality and functionality.</li>
            <li><strong>Deployment:</strong> We deploy the solution and provide ongoing support to ensure success.</li>
          </ul>
          </div>
        </section>
        <section className="aboutcontent container" >
            <div className='para'>

            <p>So if you are searching for a reliable and one of the best software company in Nepal look no further than Bluebug Software Nepal. As one of the best IT companies in Nepal and a leading software development company in Nepal, We have you covered in every aspect of your need from custom software and website to grow your business to elegant portfolio to showcase your skills and productivity. Bluebug Software is a leading provider of customized software development services in Nepal, making sure your company receives the recognition it merits.</p>
            <p>BlueBug Software is available to assist you, regardless of whether you're interested in website development in Nepal or require a trustworthy partner for your startup business in Nepal. As one of Nepal's top IT companies, we provide the best digital services and software. We are the greatest option for companies looking for cutting-edge IT solutions since our products are made to keep you one step ahead of the competition.</p>
            <p>Our service ranges from Software development to Mobille app development and SEO optimization.our goal is to deliver unmatched quality and value. If you're looking for the best software development company in Nepal or the best IT services in the region, BlueBug Software is your ideal partner.</p>
            <p>Experience the difference with BlueBug Software Nepal, your vision, our code where your success is our mission . Contact us today and discover why we are the best software company in Nepal, dedicated to providing top-notch services that drive your business forward.</p>
            </div>
        </section>
          <Footer />

        
      </div>
    </section>
    
      </main>
  );
}

export default AboutUs;
