import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaBriefcase, FaPhone } from 'react-icons/fa';
import { GoHomeFill } from 'react-icons/go';
import { PiBugDroidFill } from 'react-icons/pi';
import { FaBloggerB } from 'react-icons/fa';
import './Sidebar.css';

const Sidebar = () => {
  const navigate = useNavigate();

  const handleContactClick = (event) => {
    event.preventDefault();
    navigate('/', { state: { scrollToContact: true } });
  };

  return (
    <nav className="sidebar glass-effect">
      <ul className="sidebar-menu">
        <li className="sidebar-item">
          <Link to="/" onClick={() => window.scrollTo(0, 0)}>
            <GoHomeFill className="sidebar-icon" />
            <span className="sidebar-text">Home</span>
          </Link>
        </li>
        <li className="sidebar-item">
          <Link to="/about">
            <PiBugDroidFill className="sidebar-icon" />
            <span className="sidebar-text">About Us</span>
          </Link>
        </li>
        <li className="sidebar-item">
          <Link to="/blogs">
            <FaBloggerB className="sidebar-icon" />
            <span className="sidebar-text">Blogs</span>
          </Link>
        </li>
        <li className="sidebar-item">
          <Link to="/career">
            <FaBriefcase className="sidebar-icon" />
            <span className="sidebar-text">Career</span>
          </Link>
        </li>
        <li className="sidebar-item">
          <a href="#contact" onClick={handleContactClick}>
            <FaPhone className="sidebar-icon" />
            <span className="sidebar-text">Contact Us</span>
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Sidebar;