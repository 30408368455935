import React from 'react';
import './Expertise.css'; // Ensure you have a CSS file for styles
import exp1 from './img/product1.webp'
import exp2 from './img/cms.webp'
import exp3 from './img/sms.webp'
import exp4 from './img/ecom.webp'
const Expertise = () => {
  const cards = [
    {
      imgSrc: exp1,
      title: 'Business Website -',
      items: ['Fully Responsive', 'Great Designs', 'Performance Focused', 'SEO Optimized'],
    },
    {
      imgSrc: exp2,
      title: 'CMS -',
      items: ['User-Friendly Interface', 'Easy Content Updates', 'Role-Based Access Control', 'Customizable Templates'],
    },
    {
      imgSrc: exp3,
      title: 'School Management System -',
      items: ['Student and Staff Management', 'Attendance and Grade Tracking', 'Fee and Finance Management', 'Parent and Student Portals'],
    },
    {
      imgSrc: exp4,
      title: 'E-commerce Website & App -',
      items: ['User-Friendly Interface', 'Easy Content Updates', 'Role-Based Access Control', 'Customizable Templates'],
    },
  ];

  return (
    <section className="expertise">
      <h2>Our Expertise</h2>
      <div className="expertise-cards">
        {cards.map((card, index) => (
          <div key={index} className="expertise-card">
            <img src={card.imgSrc} alt={card.title} className="card-img" />
            <div className="card-content">
              <h3 className="card-title">{card.title}</h3>
              <ul className="card-list">
                {card.items.map((item, idx) => (
                  <li key={idx}>{item}</li>
                ))}
              </ul>
              <button className="get-quote btn"><a href='https://forms.gle/uuK6vcG3f1r56MhE9' target='blank'>Get Quote</a></button>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Expertise;
