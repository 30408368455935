import React, { useEffect, useRef } from 'react';
import './Background.css';

const Background = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    // Function to create a light at a random edge or corner
    const createLight = () => {
      const edgeMargin = 100; // Margin from the edges
      const randomEdge = Math.floor(Math.random() * 4);

      let x, y;
      switch (randomEdge) {
        case 0: // Top edge
          x = Math.random() * canvas.width;
          y = Math.random() * edgeMargin;
          break;
        case 1: // Bottom edge
          x = Math.random() * canvas.width;
          y = canvas.height - Math.random() * edgeMargin;
          break;
        case 2: // Left edge
          x = Math.random() * edgeMargin;
          y = Math.random() * canvas.height;
          break;
        case 3: // Right edge
          x = canvas.width - Math.random() * edgeMargin;
          y = Math.random() * canvas.height;
          break;
        default:
          x = Math.random() * canvas.width;
          y = Math.random() * canvas.height;
          break;
      }

      return {
        x,
        y,
        radius: Math.min(canvas.width, canvas.height) * 0.5,
        opacity: 0
      };
    };

    // Initialize two lights
    let lights = [createLight(), createLight()];
    let increasing = [true, true];

    const animate = (time) => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      const opacityChange = 0.002; // Adjust this value to slow down the animation

      // Update the opacity for both lights
      lights.forEach((light, index) => {
        if (increasing[index]) {
          light.opacity += opacityChange;
          if (light.opacity >= 1) {
            increasing[index] = false;
          }
        } else {
          light.opacity -= opacityChange;
          if (light.opacity <= 0) {
            increasing[index] = true;
            lights[index] = createLight(); // Create a new light when the opacity fades out
          }
        }

        const gradient = ctx.createRadialGradient(light.x, light.y, 0, light.x, light.y, light.radius);
        gradient.addColorStop(0, `rgba(0, 100, 255, ${light.opacity})`);
        gradient.addColorStop(1, 'rgba(0, 100, 255, 0)');

        ctx.beginPath();
        ctx.fillStyle = gradient;
        ctx.arc(light.x, light.y, light.radius, 0, Math.PI * 2);
        ctx.fill();
      });

      requestAnimationFrame(animate);
    };

    animate();

    const handleResize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      lights = [createLight(), createLight()]; // Recreate lights on resize
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return <canvas ref={canvasRef} className="background"></canvas>;
};

export default Background;
