import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Background from './Background';
import Expertise from './Expertise';
import Services from './Services';
import Sidebar from './Sidebar';
import Footer from './Footer';
import AboutUs from './AboutUs';
import Blogs from './Blogs';
import BlogContent from './BlogContent';
import CareerPage from './CareerPage';
import './App.css';
import bug from './img/bug.webp'
import logo from './img/logo.webp';
import Contact from './Contact';

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const location = useLocation();

  useEffect(() => {
    if (location.state?.scrollToContact) {
      setTimeout(() => {
        const contactSection = document.getElementById('contact');
        if (contactSection) {
          contactSection.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    }
  }, [location]);

  return (
    <div className="app-container">
      <div className="logo-container">
        <img src={logo} alt="Logo" className="logo" />
      </div>
      <div className='main'>
        <Background />
        <div className="main-container">
          <Sidebar />
          <Routes>
            <Route path="/" element={<MainContent />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/career" element={<CareerPage />} />
            <Route path="/blogpost" element={<BlogContent />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

function MainContent() {
  return (
    <main className="main-content glass-effect">
      <Home />
      <Motto />
      <Services />
      <Expertise />
      <Testimonials />
      <Contact />
      <Footer />
    </main>
  );
}
function Home() {
  return (
    <section className="home">
      <p>Welcome to Bluebug Software!</p>
      <h1 className="typewriter">Your Vision, Our Code.</h1>
      <a href='#contact'><div className='btn'>
        Let's Build Together &rarr;
      </div></a>
      <h5>scroll down &darr;</h5>
    </section>
  );
}

function Motto() {
  return (
    <section className="motto">
      <div className='motto-left'>

      <h2>Our Motto</h2>
      <h4>INNOVATE.</h4>
      <h4>INTEGRATE.</h4>
      <h4>INSPIRE.</h4>
      </div>
      <div className='motto-right'>
        <img src={bug} alt='motto-img'></img>
      </div>
      {/* Add your company motto */}
    </section>
  );
}





function Testimonials() {
  return (
    <section className="testimonials">
      <h2>Testimonials</h2>
      <div className="testimonial-carousel">
        <div className="testimonial-track">
          <div className="testimonial-slide">
            <p>"Bluebug Software’s CRM system has revolutionized how we manage our customer relationships at Isha Traders. The tailored CRM solution has streamlined our operations, improved our customer interactions, and significantly boosted our sales efficiency. The user-friendly interface and robust features make it an invaluable tool for our team. The support and customization options provided have been top-notch, and we couldn’t be happier with the results."</p>
            <h4>Ramesh Karki</h4>
            <p>CEO, Isha Traders.</p>
          </div>
          <div className="testimonial-slide">
            <p>"Working with Bluebug Software has been a game-changer for Connectifi Solutions. Their expertise in website development allowed us to achieve a stunning and functional online presence. From the initial consultation to the final launch, their team demonstrated exceptional professionalism and attention to detail. Our website now stands out in a crowded market, and we’ve seen a significant increase in user engagement and satisfaction. Highly recommended!"
            </p>
            <h4>Saurab Gautam</h4>
            <p>Founder & CEO, Connectifi Solutions.</p>
          </div>
          <div className="testimonial-slide">
            <p>"Bluebug Software’s cafe management system has transformed the way we run Cafe Haven. The system is incredibly intuitive and has made managing orders, inventory, and customer preferences a breeze. We’ve seen a marked improvement in our operational efficiency and customer service. The team at Bluebug Software was very responsive and went above and beyond to ensure the system met all our needs. It’s been a great investment for our business!"</p>
            <h4>Raman Patel</h4>
            <p>Owner, Cafe Haven.</p>
          </div>
          {/* Add more testimonial slides as needed */}
        </div>
      </div>
    </section>
  );
}



export default App;