import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Blogs.css'; // Ensure this file exists and is properly imported

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  const navigate = useNavigate(); // Use navigate for programmatic navigation

  useEffect(() => {
    fetchBlogs();
  }, []);

  const fetchBlogs = async () => {
    try {
      const response = await fetch('https://admin.bluebugsoft.com/get_blogs.php');
      const data = await response.json();
      setBlogs(data.blogs);
    } catch (error) {
      console.error('Error fetching blogs:', error);
    }
  };

  const handleBlogClick = (id) => {
    navigate(`/blogpost?id=${id}`); // Use query parameters for navigation
  };

  return (
    <main className="main-content glass-effect">
      <h2 className='blogs-heading'>Blogs</h2>
      <section className="blogs">
        <div className="blog-collection">
          {blogs.map((blog) => (
            <div key={blog.id} className="blog-item">
              <div onClick={() => handleBlogClick(blog.id)} style={{ cursor: 'pointer' }}>
                <img src={blog.image} alt={`${blog.title}-Blog image`} />
                <div className="title">
                  {blog.title}
                </div>
                <div className="content">
                  <div className="common-overview-description" dangerouslySetInnerHTML={{ __html: blog.content }} />
                </div>
                <div className='nav-btn' style={{ color: '#069FCE' }}>Read more. &rarr;</div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </main>
  );
};

export default Blogs;
