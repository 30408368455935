import React from "react";

function Footer() {
    return (
      <section className="footer">
        <div className="footer-top">

        <p><a href="mailto:info@bluebugsoft.com">Email: info@bluebugsoft.com</a></p>  <p><a href="tel: +977-9829303050">Phone/Whatsapp: +977-9829303050</a></p> 
        <p><a href="https://maps.app.goo.gl/W4DSiKMJeQyN76uh7" target="blank">Location: Akashedhara, Kapan, Kathmandu </a></p>
        </div>
        <p>&copy; All Rights Reserved Bluebug Software.</p>
      </section>
    );
  }

export default Footer