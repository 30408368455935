import React, { useState } from 'react';

const Contact = () => {
  const [formData, setFormData] = useState({
    fullname: '',
    email: '',
    phone: '',
    address: '',
    message: ''
  });

  const [errors, setErrors] = useState({
    email: '',
    phone: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const validateForm = () => {
    let valid = true;
    let emailError = '';
    let phoneError = '';

    // Email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formData.email)) {
      emailError = 'Invalid email address';
      valid = false;
    }

    // Phone validation (basic pattern for demonstration)
    const phonePattern = /^[0-9]{10}$/;
    if (!phonePattern.test(formData.phone)) {
      phoneError = 'Phone number must be 10 digits';
      valid = false;
    }

    setErrors({
      email: emailError,
      phone: phoneError
    });

    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return; // Exit if validation fails
    }

    try {
      const response = await fetch('https://bluebugsoft.com/contact_us.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(formData)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const text = await response.text();
      let result;
      try {
        result = JSON.parse(text);
      } catch (error) {
        console.error('Error parsing JSON:', error);
        throw new Error('Invalid JSON response from server');
      }

      if (result.status === 'success') {
        alert(result.message);
        setFormData({
          fullname: '',
          email: '',
          phone: '',
          address: '',
          message: ''
        });
      } else {
        throw new Error(result.message || 'Unknown error occurred');
      }
    } catch (error) {
      console.error('Error:', error);
      alert(`There was an error submitting the form: ${error.message}`);
    }
  };

  return (
    <section className="contact" id="contact">
      <div className='contact-us'>
        <div className='contact-us-left'>
          <p>Contact us now.</p>
          <h4>Let's Build Something Great Together.</h4>
        </div>
        <div className='contact-us-right'>
          <form onSubmit={handleSubmit}>
            <input
              type='text'
              id='fullname'
              name='fullname'
              placeholder='Fullname'
              value={formData.fullname}
              onChange={handleChange}
              required
            /><br />
            <input
              type='email'
              id='email'
              name='email'
              placeholder='Email'
              value={formData.email}
              onChange={handleChange}
              required
            />
            {errors.email && <div className='error'>{errors.email}</div>}<br />
            <input
              type='tel'
              id='phone'
              name='phone'
              placeholder='Phone'
              value={formData.phone}
              onChange={handleChange}
              required
              pattern="[0-9]{10}"
            />
            {errors.phone && <div className='error'>{errors.phone}</div>}<br />
            <input
              type='text'
              id='address'
              name='address'
              placeholder='Address'
              value={formData.address}
              onChange={handleChange}
              required
            /><br />
            <textarea
              placeholder='Your Message'
              name='message'
              value={formData.message}
              onChange={handleChange}
              required
            /><br />
            <input type='submit' className='btn' value='Submit &rarr;' />
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
