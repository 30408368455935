import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import './BlogContent.css'; // Ensure this file exists and is properly imported

const BlogContent = () => {
  const [searchParams] = useSearchParams(); // Get search params from URL
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const id = searchParams.get('id'); // Retrieve the ID from query params
        if (!id) {
          setError('No blog ID provided.');
          setLoading(false);
          return;
        }

        const url = `https://admin.bluebugsoft.com/get_blogcontent.php?id=${id}`;
        console.log(`Fetching data from: ${url}`);

        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Network response was not ok.');
        }
        const data = await response.json();
        console.log('Fetched data:', data); // Add this line to debug
        if (data.blog) {
          setBlog(data.blog);
        } else {
          setError('Blog not found.');
        }
      } catch (error) {
        console.error('Error fetching blog:', error);
        setError('Error fetching blog.');
      } finally {
        setLoading(false);
      }
    };

    fetchBlog();
  }, [searchParams]); // Depend on searchParams to refetch on change

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <main className="main-content glass-effect">
      <div className="blog-content">
        {blog && (
          <>
            <h1>{blog.title}</h1>
            <img src={blog.image} alt={blog.title} />
            <div className="content" dangerouslySetInnerHTML={{ __html: blog.content }} />
            {/* <p className="date">{blog.created_at}</p> */}
          </>
        )}
      </div>
    </main>
  );
};

export default BlogContent;
